import styled from "@emotion/styled"

export const NavStyles = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* inset: 0; */
  background-color: rgb(0 0 0 / 0.97);
  color: var(--white);
  overflow-y: scroll;
  padding: 3rem 5vw;
  z-index: 100;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &.nav-overlay {
    &-enter {
      opacity: 0;
      transform: scale(1.3);
    }

    &-enter-active {
      opacity: 1;
      transform: scale(1);
      transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out,
        0.33s transform ease-in-out;
    }

    &-exit {
      opacity: 1;
      transform: scale(1);
    }

    &-exit-active {
      opacity: 0;
      transform: scale(1.3);
      transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out,
        0.33s transform ease-in-out;
    }
  }
`

export const NavOverlayHeader = styled.div`
  height: calc(4.8rem + 8.3rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0 calc(100vw - 137rem);
  max-width: 460ch;

  @media screen and (max-width: 40em) {
    max-width: 30ch;
  }

  h3 {
    font-size: 4.3rem;
  }

  p {
    color: rgb(255 255 255 / 0.45);
  }
`

export const NavOverlayLinks = styled.nav`
  display: flex;
  justify-content: center;
  max-width: 112rem;
  width: 90%;
  margin: 8rem auto;

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    justify-content: space-evenly;
    gap: 4rem;
    counter-reset: listCount;
    padding: 0 5vw;
    width: 100%;

    @media screen and (max-width: 40em) {
      gap: 3rem;
    }

    li {
      counter-increment: listCounter;
      display: flex;
    }

    li::before {
      content: counter(listCounter) ". ";
      color: rgb(255 255 255 / 0.35);
      font-size: 1.2em;
      padding-right: 1rem;
    }

    a {
      font-size: 2.2rem;
      text-transform: uppercase;
      font-weight: 300;
      display: block;
      width: 100%;

      &:hover {
        color: var(--primaryRed);
      }
    }

    a:hover,
    li:hover::before {
      color: var(--primaryRed);
    }
  }
`
