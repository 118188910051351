import React from "react"
import { graphql, useStaticQuery } from "gatsby"

// Components
import {
  StyledQuickLinks,
  FeaturedNav,
  FeaturedNavItem,
  FeaturedNavLinks,
} from "./QuickLinksElements"

const getQuickLinks = graphql`
  query {
    quickLinks: allContentfulQuickLinks(
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        id: contentful_id
        text
        slug
      }
    }
  }
`

const QuickLinks = () => {
  const data = useStaticQuery(getQuickLinks)
  const quickLinks = data.quickLinks.nodes

  return (
    <StyledQuickLinks>
      <FeaturedNav>
        {quickLinks.map(({ id, text, slug }) => {
          return (
            <FeaturedNavItem key={id}>
              <FeaturedNavLinks to={`/${slug}/`}>{text}</FeaturedNavLinks>
            </FeaturedNavItem>
          )
        })}
      </FeaturedNav>
    </StyledQuickLinks>
  )
}

export default QuickLinks
