import styled from "@emotion/styled"
import { Link } from "gatsby"

export const CategoryMenuList = styled.ul`
  /* display: flex;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  gap: 2rem 3rem;

  @media (max-width: 60em) {
    order: 0;
    flex: 1;
    gap: 0 2rem;
  }
`

export const CategoryMenuItemLink = styled(Link)`
  font-size: 1.7rem;
  font-weight: 700;
  display: block;
  padding-bottom: 2rem;
`

export const SubCategoryMenuList = styled.ul`
  --show-hide: flex;
  display: var(--show-hide);
  flex-direction: column;

  @media (max-width: 60em) {
    --show-hide: none;
    display: var(--show-hide);
  }
`
