exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contentful-about-page-slug-js": () => import("./../../../src/pages/{ContentfulAboutPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-about-page-slug-js" */),
  "component---src-pages-contentful-category-slug-js": () => import("./../../../src/pages/{ContentfulCategory.slug}.js" /* webpackChunkName: "component---src-pages-contentful-category-slug-js" */),
  "component---src-pages-contentful-category-sub-category-parent-category-slug-contentful-category-sub-category-slug-js": () => import("./../../../src/pages/{ContentfulCategorySubCategory.parentCategory__slug}/{ContentfulCategorySubCategory.slug}.js" /* webpackChunkName: "component---src-pages-contentful-category-sub-category-parent-category-slug-contentful-category-sub-category-slug-js" */),
  "component---src-pages-contentful-quote-category-slug-contentful-quote-sub-category-slug-js": () => import("./../../../src/pages/{ContentfulQuote.category__slug}/{ContentfulQuote.subCategory__slug}.js" /* webpackChunkName: "component---src-pages-contentful-quote-category-slug-contentful-quote-sub-category-slug-js" */),
  "component---src-pages-contentful-quote-category-slug-index-js": () => import("./../../../src/pages/{ContentfulQuote.category__slug}/index.js" /* webpackChunkName: "component---src-pages-contentful-quote-category-slug-index-js" */),
  "component---src-pages-contentful-review-category-slug-contentful-review-sub-category-slug-contentful-review-slug-js": () => import("./../../../src/pages/{ContentfulReview.category__slug}/{ContentfulReview.subCategory__slug}/{ContentfulReview.slug}.js" /* webpackChunkName: "component---src-pages-contentful-review-category-slug-contentful-review-sub-category-slug-contentful-review-slug-js" */),
  "component---src-pages-contentful-review-category-slug-index-js": () => import("./../../../src/pages/{ContentfulReview.category__slug}/index.js" /* webpackChunkName: "component---src-pages-contentful-review-category-slug-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-contentful-legal-slug-js": () => import("./../../../src/pages/legal/{ContentfulLegal.slug}.js" /* webpackChunkName: "component---src-pages-legal-contentful-legal-slug-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-quote-js": () => import("./../../../src/templates/Quote.js" /* webpackChunkName: "component---src-templates-quote-js" */)
}

