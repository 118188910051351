module.exports = {
  siteName: "The Business Frontal",
  siteTitle: "Entrepreneurship & Business Magazine", // This allows an alternative site title for meta data for pages.
  siteDescription:
    "The Business Frontal provides great insights, guides, and advice on how to start a business and succeed", // This allows an alternative site description for meta data for pages.
  siteImage: "/business-frontal.jpg",
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 628, // Change to the height of your default share image
  siteUrl: "https://businessfrontal.com", // Site domain. Do not include a trailing slash!
  twitter: "@businessfrontal",

  postsPerPage: 25, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  shortTitle: "The Business Frontal", // Used for App manifest e.g. Mobile Home Screen
  siteIcon: "./favicon.png", // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: "#e9e9e9", // Used for Offline Manifest
  themeColor: "#17519F", // Used for Offline Manifest
}
