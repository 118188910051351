import styled from "@emotion/styled"
import { Link } from "gatsby"

export const StyledQuickLinks = styled.div`
  margin: var(--margin-center);
  padding: var(--padding-fixed-width);
  padding-bottom: 3rem;
  /* padding-bottom: 1.5rem; */
  padding-top: 2rem;
  /* position: sticky; */
  top: 0;
  width: var(--default-width);
  /* z-index: 10; */

  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FeaturedNav = styled.ul`
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const FeaturedNavLinks = styled(Link)`
  color: currentColor;
  font-style: var(--mediumFont);
  font-weight: 700;
  position: relative;
  text-decoration: none;
  transition: var(--transition);
  word-spacing: -3.3px;

  &:hover,
  &.active {
    border-bottom: 3px solid var(--primaryRed);
    color: var(--primaryRed);
    padding-bottom: 2.8rem;
    /* padding-bottom: 1.3rem; */
  }
`

export const FeaturedNavItem = styled.li`
  &:not(:last-of-type) {
    margin-right: 2rem;

    @media (max-width: 28.75em) {
      margin-right: 1.4rem;
    }
  }
`
export const MobileIcon = styled.div`
background: var(--brandGrey);
  border: 1px solid var(--grey-4);
  border-radius: 9999px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
  color: var(--grey-8);
  color: #635e69;
  cursor: pointer;
  font-size: 2.2rem;
  padding: 0.44em 0.49em;
  transition: 0.3s all ease-in;
  transition: all 550ms ease-in;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    fill: var(--white);
  }

  &:hover,
  &:focus {
    color: var(--grey-7);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
    transform: translateY(-0.2rem);
  }
  &:active {
    transform: translateY(0);
  }
`
