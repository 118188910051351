import React from "react"
import { Link } from "gatsby"

import {
  FooterStyles,
  FooterWrapper,
  CopyrightWrapper,
  CopyrightContent,
  FooterNav,
  InfoLinks,
  InfoLinksList,
} from "./FooterElements"
import Icon from "../../utils/Icon"
import SocialIcons from "../../utils/SocialIcons/index"
import infoLinks from "../../constants/info-links"
import Navigation from "../Navigation"

const Footer = () => {
  return (
    <FooterStyles>
      <FooterWrapper>
        <CopyrightWrapper>
          <Icon />
          <CopyrightContent>
            <a
              className="phone-num"
              href="tel:+12027318606"
              rel="noopener noreferrer"
            >
              <strong>(240) 460-8329</strong>
            </a>
            <br />
            <span className="small muted">Maryland, USA</span>

            <p className="small muted">
              © {new Date().getFullYear()},<br />
                <Link to="/">The Business Frontal</Link>.<br />
              All Rights Reserved
            </p>
          </CopyrightContent>
        </CopyrightWrapper>
        <FooterNav>
          <h4>All Sections</h4>
          <Navigation handleToggle={null} footer={true} />
        </FooterNav>
        <InfoLinks>
          <h4>Info Links</h4>
          <InfoLinksList>
            {infoLinks.map(infoLink => {
              return (
                <li key={infoLink.id}>
                  <Link to={infoLink.path}>{infoLink.label}</Link>
                </li>
              )
            })}
          </InfoLinksList>
          <SocialIcons className="spacing" />
          <a
            className="best-spinner"
            href="https://thebestspinner.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            The Best Spinner
          </a>
        </InfoLinks>
      </FooterWrapper>
    </FooterStyles>
  )
}

export default Footer
