import styled from "@emotion/styled"
import { Link } from "gatsby"

export const HeaderStyles = styled.header`
  background-color: var(--grey-1);
  border-bottom: 1px solid var(--grey-3);
  grid-column: full-start / full-end;
  margin-block-end: 0;

  /* .skip-nav-link {
    background: var(--black);
    border-radius: 0 0 0.25rem 0;
    color: var(--white);
    position: absolute;
    left: 0.5rem;
    padding: 0.5rem 1.5rem;
    transform: translateY(-120%);
    transition: transform 325ms ease-in;

    &:focus {
      position: absolute;
      transform: translateY(0);
    }
  } */
`

export const TickerTapeWrapper = styled.section`
  min-height: 4.6rem;
  overflow: hidden;
  transition: all 0.35s ease-in-out;

  @media (max-width: 767px) {
    min-height: 7.4rem;
  }

  div:first-of-type div:nth-child(2) {
    display: none;
  }
`
export const Topbar = styled.div`
  border-bottom: 1px solid var(--grey-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--margin-center);
  padding: var(--padding-fixed-width);
  /* opacity: 0.75; */
  width: var(--default-width);

  .logo {
    height: 4.6rem;
  }
`

export const DateTime = styled.time`
  font-size: 1.4rem;
  opacity: 0.75;

  @media (max-width: 40em) {
    display: none;
  }
`

export const SharingIcons = styled.div``

export const QuickLinks = styled.ul`
  display: flex;
`
export const LinkItem = styled.li`
  margin-right: 1.25rem; ;
`
export const QuickLink = styled(Link)`
  color: currentColor;
`

export const Menu = styled.div``

// Search and Nav
export const Nav = styled.div`
  display: flex;
  align-items: center;
`

// Search Form
export const SearchFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  /* margin-inline-end: 1rem; */
  min-height: 2rem;

  .screen-reader-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }
`

export const SearchBtn = styled.button`
  /* background: var(--grey-4); */
  background: var(--brandGrey);
  border: 1px solid transparent;
  /* border-radius: 4px; */
  border-radius: 50%;
  box-sizing: border-box;
  /* color: var(--white); */
  color: var(--grey-7);
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.5rem;
  line-height: 1;
  min-height: 4.1rem;
  min-width: 4.2rem;
  padding-block-end: 0.8rem;
  padding-block-start: 0.6rem;
  padding-inline: 0.6rem;
  transition: all 550ms ease-in;
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: var(--grey-5);
    border: 1px solid var(--grey-5);
    color: var(--grey-8);
    /* background: var(--grey-8); */
    /* border: 1px solid var(--grey-8); */
    /* color: var(--white); */
  }

  svg {
    flex-shrink: 0;
    margin: 0 0.25rem;
    transform: scale(1);
    transform: translateY(10%) scale(2);
    /*fill: currentColor;*/
    fill: var(--white);
  }
`

export const SearchFormStyle = styled.div`
  display: none;
`
