import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"

import config from "../utils/siteConfig"

const siteQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        siteName: name
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`

const SEO = ({
  children,
  lang,
  title,
  description,
  image,
  imageAlt,
  article,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(siteQuery)

  const {
    siteName,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      htmlAttributes={{ lang }}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={`https:${seo.image}`} />
      <link rel="alternate icon" href="/favicon.ico" />
      <link rel="canonical" href={seo.url} />

      {/* Facebook Open Graph */}
      <meta property="og:site_name" content={siteName} key="ogsitename" />
      <meta property="og:locale" content="en_US" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && (
        <meta property="og:title" content={seo.title} key="ogtitle" />
      )}
      {seo.description && (
        <meta
          property="og:description"
          content={seo.description}
          key="ogdesc"
        />
      )}
      {seo.image && (
        <meta
          property="og:image"
          content={seo.image ? `https:${seo.image}` : `${seo.image}`}
        />
      )}
      {seo.image && (
        <meta
          property="og:image:width"
          content={String(config?.shareImageWidth)}
        />
      )}
      {seo.image && (
        <meta
          property="og:image:height"
          content={String(config?.shareImageHeight)}
        />
      )}
      {(article ? true : null) && <meta property="og:type" content="article" />}

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:site" content={twitterUsername} />
      )}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && (
        <meta
          name="twitter:image"
          content={seo.image ? `https:${seo.image}` : `${seo.image}`}
        />
      )}
      {seo.image && <meta name="twitter:image:alt" content={imageAlt} />}

      {/* Search Engine Verification */}
      <meta
        name="google-site-verification"
        content="KWRcVF88c30fMS1Ky8QoVoANDecaDB0laa9Jy5KoDHE"
      />
      <meta
        name="meseek-verification"
        content="44c4c17332cace2124a1a836d9fc4b6f"
      />
      <meta name="yandex-verification" content="76df3cea60bafa8c" />

      {children}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  lang: `en-US`,
  title: null,
  description: null,
  image: null,
  article: false,
}
