import React, { useState } from "react"
import { FaBars } from "react-icons/fa"
import { CSSTransition } from "react-transition-group"
import { TickerTape } from "react-ts-tradingview-widgets"

import TickerSymbols from "../../utils/TickerSymbols"
// import Search from "../../components/Search"

import SkipNavLink from "../../utils/SkipNavLink"
import { HeaderStyles, Topbar, Nav, TickerTapeWrapper } from "./HeaderElements"
import HeaderBanner from "../../components/HeaderBanner"
import QuickLinks from "../QuickLinks"
import { MobileIcon } from "../QuickLinks/QuickLinksElements"
import NavOverlay from "../Nav"
// import SocialIcons from "../../utils/SocialIcons"
import SearchForm from "./SearchForm"
// import Overlay from "../Overlay"

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen)
  }

  return (
    <HeaderStyles>
      <SkipNavLink />
      <TickerTapeWrapper>
        <TickerTape colorTheme="dark" symbols={TickerSymbols} />
      </TickerTapeWrapper>

      <Topbar>
        <SearchForm />
        {/* <Overlay /> */}
        <HeaderBanner />
        <Nav>
          <MobileIcon onClick={handleToggle}>
            <FaBars aria-label="main navigation menu" />
          </MobileIcon>
        </Nav>
      </Topbar>
      <CSSTransition
        timeout={330}
        in={isNavOpen}
        classNames="nav-overlay"
        unmountOnExit
      >
        <NavOverlay handleToggle={handleToggle} />
      </CSSTransition>

      <QuickLinks />
    </HeaderStyles>
  )
}

export default Header
