const infoLinks = [
  {
    id: 1,
    path: "/about-business-frontal/",
    label: "About",
  },
  {
    id: 2,
    path: "/contact/",
    label: "Contact",
  },
  {
    id: 3,
    path: "/legal/privacy-policy/",
    label: "Privacy Policy",
  },
  {
    id: 4,
    path: "/legal/terms-of-service/",
    label: "Terms of Service",
  },
  {
    id: 5,
    path: "/sitemap/",
    label: "Sitemap",
  },
]

export default infoLinks
