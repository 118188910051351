const symbols = [
  {
    proName: "FOREXCOM:DJI",
    title: "Dow Jones",
  },
  {
    proName: "FOREXCOM:SPXUSD",
    title: "S&P 500",
  },
  {
    proName: "OANDA:NAS100USD",
    title: "NASDAQ",
  },
  {
    proName: "FOREXCOM:NSXUSD",
    title: "US 100",
  },
  {
    proName: "NYMEX:CL1!",
    title: "Crude Oil",
  },
  {
    proName: "COMEX:GC1!",
    title: "Gold",
  },
  {
    proName: "BITSTAMP:BTCUSD",
    title: "Bitcoin",
  },
  {
    proName: "BITSTAMP:ETHUSD",
    title: "Ethereum",
  },
  {
    proName: "NASDAQ:AAPL",
    title: "APPL",
  },
  {
    proName: "NASDAQ:GOOG",
    title: "GOOG",
  },
  {
    proName: "NASDAQ:AMZN",
    title: "AMZN",
  },
  {
    proName: "NASDAQ:TSLA",
    title: "TSLA",
  },
  {
    proName: "NASDAQ:MSFT",
    title: "MSFT",
  },
  {
    proName: "NASDAQ:NFLX",
    title: "NFLX",
  },
  {
    proName: "NASDAQ:META",
    title: "META",
  },
  {
    proName: "OANDA:EURUSD",
    title: "EUR/USD",
  },
  {
    proName: "OANDA:USDJPY",
    title: "USD/JPY",
  },
  {
    proName: "OANDA:GBPUSD",
    title: "GBP/USD",
  },
  {
    proName: "OANDA:AUDUSD",
    title: "AUD/USD",
  },
  {
    proName: "OANDA:USDCAD",
    title: "USD/CAD",
  },
]

export default symbols
