import styled from "@emotion/styled"

export const StyledModalRoot = styled.div``

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0 0;
  width: 100%;
  z-index: 200;
  /* pointer-events: none; */

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 0.7);
`

export const StyledModal = styled.div`
  background: var(--white);
  width: calc(100% - 4rem);
  max-width: 86rem;
  height: 70vh;
  border-radius: 12px;
  padding: 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 2.5rem;

  .closeBtn {
    cursor: pointer;
    width: 4.8rem;
    height: 4.8rem;
    background-color: var(--grey-4);
    border-radius: 50rem;
    padding: 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--grey-6);
      padding: 0.5rem;
    }
  }
`

export const ModalBody = styled.section`
  padding-top: 10px;
`
