import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

// Styles
import {
  CategoryMenuItemLink,
  CategoryMenuList,
  SubCategoryMenuList,
} from "../Navigation/NavigationElements"

const linksQuery = graphql`
  query MenuLinks {
    category: allContentfulCategory(sort: { fields: order, order: ASC }) {
      nodes {
        id: contentful_id
        title
        slug
        subCategories {
          subCatId: id
          title
          slug
          article {
            slug
          }
        }
      }
    }
  }
`

const Navigation = ({ handleToggle, footer }) => {
  const data = useStaticQuery(linksQuery)
  const links = data.category.nodes

  return (
    <>
      <CategoryMenuList>
        {links.map(link => {
          return (
            <li key={link.id}>
              {footer ? (
                <CategoryMenuItemLink
                  to={`/${link.slug}/`}
                  activeStyle={{ color: "red", fontWeight: "700" }}
                >
                  {link.title}
                </CategoryMenuItemLink>
              ) : (
                <CategoryMenuItemLink
                  to={`/${link.slug}/`}
                  activeStyle={{ color: "red", fontWeight: "700" }}
                  onClick={handleToggle}
                >
                  {link.title}
                </CategoryMenuItemLink>
              )}

              {footer && link?.subCategories.length !== 0 && (
                <SubCategoryMenuList>
                  {link?.subCategories.map(subCat => {
                    return (
                      <li key={subCat.subCatId}>
                        <Link
                          to={`/${link.slug}/${subCat.slug}/`}
                          activeStyle={{ color: "red", fontWeight: "700" }}
                          partiallyActive={true}
                        >
                          {subCat.title}
                        </Link>
                      </li>
                    )
                  })}
                </SubCategoryMenuList>
              )}
            </li>
          )
        })}
      </CategoryMenuList>
    </>
  )
}

export default Navigation
