import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { FaTimes } from "react-icons/fa"

import {
  StyledModal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
} from "./ModalElements"

const Modal = ({ children, show, title, onClose }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => setIsBrowser(true), [])

  const handleClick = e => {
    e.preventDefault()
    onClose()
  }

  const modalContent = show ? (
    <ModalOverlay>
      <StyledModal>
        <ModalHeader>
          {/* <a href="#" > */}
          <FaTimes className="closeBtn" onClick={handleClick} />
          {/* </a> */}
        </ModalHeader>
        {title && <div>{title}</div>}
        <ModalBody>{children}</ModalBody>
      </StyledModal>
    </ModalOverlay>
  ) : null

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      // @ts-ignore
      document.getElementById("modal-root")
    )
  } else {
    return null
  }
}

export default Modal
