import React from "react"

import SocialIconsData from "../../constants/social-icons"
import { SocialIconsStyles } from "./SocialIconsElements"

const SocialIcons = ({ className }) => (
  <SocialIconsStyles className={className}>
    {SocialIconsData.map(icon => (
      <li key={icon.id}>
        <a
          href={icon.url}
          rel="noopener noreferrer"
          target="_blank"
          title={icon.title}
        >
          {icon.icon}
        </a>
      </li>
    ))}
  </SocialIconsStyles>
)

export default SocialIcons
