import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { StyledBanner, BannerLink } from "./HeaderBannerElements"
import TodayDate from "../../utils/TodayDate"

const HeaderBanner = () => {
  return (
    <StyledBanner>
      <TodayDate />
      <BannerLink to="/">
        <StaticImage
          // src="../images/ceiimages-logo.png"
          src="../../images/business-frontal-banner.png"
          alt="Business Frontal banner logo"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
          width={360}
          quality={90}
          // imgStyle={{ marginLeft: `-7px` }}
        />
      </BannerLink>
    </StyledBanner>
  )
}

export default HeaderBanner
