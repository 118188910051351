import React, { useState, useEffect } from "react"

import { DateTime } from "../components/Header/HeaderElements"

const TodayDate = () => {
  let [date, setDate] = useState(new Date())

  useEffect(() => {
    let timer = setInterval(() => setDate(new Date()), 1000)

    return function cleanup() {
      clearInterval(timer)
    }
  }, [setDate, date])

  return <DateTime>{date.toDateString()}</DateTime>
}

export default TodayDate
