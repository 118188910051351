import React from "react"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
  FaRss,
} from "react-icons/fa"

const socialIcons = [
  {
    id: `1`,
    icon: <FaTwitter />,
    url: `https://twitter.com/businessfrontal`,
    color: `#1da1f2`,
    title: `Follow us on Twitter`,
  },
  {
    id: `2`,
    icon: <FaFacebookF />,
    url: `https://facebook.com/businessfrontal`,
    color: `#3b5998`,
    title: `Join our Facebook community`,
  },
  {
    id: `3`,
    icon: <FaLinkedinIn />,
    url: `https://linkedin.com/company/businessfrontal`,
    color: `#0e76a8`,
    title: `Visit our LinkedIn business page`,
  },
  {
    id: `4`,
    icon: <FaYoutube />,
    url: `https://youtube.com/c/businessfrontal`,
    color: `#c4302b`,
    title: `Subscribe to our YouTube channel`,
  },
  {
    id: `5`,
    icon: <FaRss />,
    url: `https://feedly.com/i/subscription/feed/https://businessfrontal.com/rss/`,
    color: `currentColor`,
    title: `Subscribe to our feed`,
  },
]

export default socialIcons
