import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

import Svg from "../../utils/BtnSvg/Svg"
import Modal from "../Modal/Modal"
import Search from "../Search"
import {
  SearchFormContainer,
  SearchBtn,
  SearchFormStyle,
} from "./HeaderElements"

const searchIndices = [
  { name: `Articles`, title: `Articles` },
  { name: `Quotes`, title: `Quotes` },
  { name: `Reviews`, title: `Reviews` },
]

const SearchForm = () => {
  const [expanded, setExpanded] = useState(false)
  const [showSearchText, setShowSearchText] = useState("Show")
  const [toggleIcon, setToggleIcon] = useState(true)
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <SearchFormContainer>
        <SearchBtn
          onClick={() => setShowModal(true)}
          type="button"
          aria-expanded={showModal}
        >
          <span className="screen-reader-only">
            {showSearchText} Search Form
          </span>
          <Svg className={!showModal ? "search-icon" : null}>
            <path
              d={
                toggleIcon
                  ? "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                  : "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              }
            />
          </Svg>
        </SearchBtn>
      </SearchFormContainer>

      <CSSTransition
        timeout={330}
        in={showModal}
        classNames="modal-overlay"
        unmountOnExit
      >
        <Modal
          title={null}
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <Search indices={searchIndices} />
        </Modal>
      </CSSTransition>
    </>
  )
}

export default SearchForm
