import styled from '@emotion/styled'

export const SocialIconsStyles = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  a {
    color: currentColor;
    opacity: 0.75;
    transition: 0.35s all ease-in-out;

    &:hover,
    &:focus {
      opacity: 0.9;
      transform: scale(1.1);
    }
  }
`
