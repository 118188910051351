import React from "react"

const Svg = ({
  className,
  children,
  color = "currentColor",
  width = "1em",
  height = "1em",
  thickness = 2,
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="<http://www.w3.org/2000/svg>"
      strokeWidth={0}
      stroke={color}
      fill={color}
      height={width}
      width={height}
    >
      {children}
    </svg>
  )
}

export default Svg
