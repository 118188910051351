import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const IconLink = styled(Link)`
  display: inline-block;
  /* max-width: 12rem;
  min-width: 8rem; */
  width: min(12rem, 75%);
`

const Icon = () => {
  return (
    <>
      <IconLink to="/">
        <StaticImage
          src="../images/business-frontal-logo-new.png"
          alt="Business Frontal Icon"
          layout="constrained"
          width={400}
          quality={90}
          // imgStyle={{ marginLeft: `-7px` }}
        />
      </IconLink>
    </>
  )
}

export default Icon
