import React from "react"
import { Global, css } from "@emotion/react"

const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        --bodyFont: "Padauk", apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, "Helvetica Neue",
          sans-serif;
        --headingFont: Vollkorn, georgia, serif;
        --headingFontVariable: VollkornVariable, georgia, serif;
        --bodyFontSize: 1.5rem;
        /* --smallFont: max(min(0.5vw, 0.5em), 1rem); */
        --smallFont: max(min(0.6vw, 0.6em), 1.4rem);
        --mediumFont: 1.8rem;
        --lineHeight: calc(var(--bodyFontSize) * 1.5);
        /* --primaryBlue: #12519f; */
        --primaryBlue: #3b88fd;
        --primaryYellow: #f7d628;
        /* --primaryRed: #ff0000; */
        --primaryRed: rgb(255, 59, 0); /* #ff3b00 */
        --success: hsl(95.4, 49.8%, 50%);
        --black: #333333;
        --white: #ffffff;
        --brandGrey: #898b8f;
        --grey-1: #fcfcfc;
        --grey-2: #f6fafd;
        --grey-3: #f7f1f1;
        --grey-4: rgba(158, 155, 155, 0.27);
        --grey-5: #d2d0d0;
        --grey-6: #bbb9b9;
        --grey-7: #9e9b9b;
        --grey-8: #3d3a3a;
        --gradient-sky: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
        --overlay-bgcolor: rgba(215, 215, 215, 0.911);
        --max-width: 144rem;
        --default-width: 90vw;
        /* --padding-fixed-width: 0 calc((100vw - 144rem) / 2); */
        --padding-fixed-width: 0 calc((100vw - 182rem) / 2);
        --margin-center: 0 auto;
        --default-grid-cols: [full-start] minmax(5vw, 1fr) [center-start]
          repeat(8, [col-start] minmax(min-content, 18rem) [col-end])
          [center-end] minmax(5vw, 1fr) [full-end];
        --transition: 0.3s all ease-in;
        --newsletter-form-width: full-start / full-end;
        --newsletter-bg: var(--grey-3);
      }

      *,
      *::before,
      *::after {
        box-sizing: inherit;
        padding: 0;
        margin: 0;
      }

      html {
        box-sizing: border-box;
        font-size: 62.5%;
        scroll-behavior: smooth;
        scrollbar-color: var(--grey-7) var(--white);
        scrollbar-width: thin;

        @media (max-width: 47.5em) {
          font-size: 57.5%;
        }
      }

      body {
        color: var(--black);
        font-family: var(--bodyFont);
        font-size: var(--bodyFontSize);
        line-height: var(--lineHeight);
        font-weight: 400;
        font-style: normal;
        font-kerning: normal;
        word-wrap: break-word;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        a {
          text-decoration: none;
          color: currentColor;
          transition: 0.35s all ease-in-out;
        }

        ul {
          list-style: none;
          margin: 0;
        }

        /* Scrollbar Styles */
        &::-webkit-scrollbar-thumb {
          /* background: var(--primaryRed); */
          background: var(--grey-6);
          border-radius: 6px;
          border: 3px solid var(--white);
        }

        &::-webkit-scrollbar {
          width: 1.2rem;
        }

        &::-webkit-scrollbar-track {
          background: var(--grey-3);
        }
      }

      ::selection {
        color: var(--white);
        background-color: var(--grey-7);
      }

      .line-clamp {
        /* color: #1d1c1c; */
        /* color: currentColor; */
        color: var(--grey-7);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: var(--headingFont);
        font-feature-settings: "kern", "liga", "clig", "calt";
        /* font-weight: 600; */
      }

      @supports (font-variation-settings: normal) {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: var(--headingFontVariable);
          font-feature-settings: "kern", "liga", "clig", "calt";
          line-height: 1.0439;
          letter-spacing: 0.2px;
          word-spacing: 3px;
        }
      }

      h1 {
        font-size: 3.8rem;
      }
      h2 {
        font-size: 2.2rem;
      }

      h3 {
        font-size: 1.7rem;
      }

      h4 {
        font-size: 1.5rem;
      }

      #gatsby-focus-wrapper {
        display: grid;
        grid-auto-rows: auto 1fr auto;
        grid-template-columns: var(--default-grid-cols);
        min-height: 100vh;
      }

      /* Fancy Gatsby image fade pixelation */
      .gatsby-image-wrapper img[src*="base64\\,"] {
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
      }
    `}
  />
)

export default GlobalStyles
