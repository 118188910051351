/** @jsx jsx */
import styled from "@emotion/styled"
import { jsx, css } from "@emotion/react"

import SearchBox from "./SearchBox"
import SearchResult from "./SearchResult"

export const StyledSearchRoot = styled.div`
  position: relative;
  margin: 0.6em 0;
`

const open = props => css`
  width: 10em;
  background: var(--white);
  cursor: text;
  padding-left: 1em;
  transform: scaleX(1.05);
`

const closed = props => css`
  width: 0;
  background: transparent;
  cursor: pointer;
  padding-left: 1em;
`

export const StyledSearchBox = styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;

  .SearchInput {
    outline: none;
    border: ${props =>
      // @ts-ignore
      props.hasFocus ? "1px solid var(--grey-7)" : "1px solid var(--grey-6)"};
    font-size: 1.5em;
    transition: 100ms;
    border-radius: 50px;
    color: #050505;
    ::placeholder {
      color: var(--grey-7);
    }
    ${props =>
      // @ts-ignore
      props.hasFocus ? open : closed}
    padding-block: 1.5rem;
    width: 100%;
    max-width: 36rem;
  }

  /* .SearchIcon {
    width: 1.5em;
    margin: -0.3em;
    font-size: 1.5em;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
  } */
`

// const Popover = css`
//   max-height: 88vh;
//   overflow: scroll;
//   -webkit-overflow-scrolling: touch;
//   position: absolute;
//   z-index: 2;
//   right: 0;
//   top: 100%;
//   margin-top: 0.5em;
//   width: 80vw;
//   box-shadow: 0 0 5px 0;
//   padding: 1em;
//   border-radius: 2px;
//   background: var(--grey-7);
// `

export const StyledSearchResult = styled(SearchResult)`
  display: ${props =>
    // @ts-ignore
    props.show ? `block` : `none`};
  /* overflow: scroll;
  -webkit-overflow-scrolling: touch; */

  .HitCount {
    display: flex;
    justify-content: flex-end;
    margin-block: 1.5rem;
    color: var(--grey-7);
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      padding: 0;

      display: flex;
      flex-direction: column;
    }

    li.ais-Hits-item {
      margin-bottom: 1.6em;
      border-bottom: 1px solid var(--grey-5);
      padding-bottom: 1rem;

      a {
        /* color: ${({ theme }) => theme.foreground}; */
        color: #050505;

        h4 {
          margin-bottom: 0.2em;
          font-size: 1.8rem;
        }
      }

      .ais-Snippet {
        color: var(--grey-7);
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;
    margin-top: 4rem;
    margin-left: auto;

    svg {
      width: 7rem;
    }
  }
`
