import React from "react"
import PropTypes from "prop-types"

import "normalize.css"

import GlobalStyles from "../styles/GlobalStyles"
import "@fontsource/vollkorn/400.css" //.
import "@fontsource/vollkorn/variable.css" // Weight 500.
import "@fontsource/vollkorn/variable-italic.css" // italic.
import "@fontsource/padauk" // Default weight = 400
import "@fontsource/padauk/700.css" // bold.

import Seo from "./seo"
import Header from "./Header"
import Footer from "./Footer"
import { StyledModalRoot } from "./Modal/ModalElements"

const Layout = ({ children }) => {
  return (
    <>
      <Seo />
      <GlobalStyles />
      <Header />
      {children}
      <Footer />
      <StyledModalRoot id="modal-root" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
