/** @jsx jsx */
import React from "react"
import { css, jsx } from "@emotion/react"

const skipNavLink = css`
  background: var(--black);
  border-radius: 0 0 0.25rem 0;
  color: var(--white);
  position: absolute;
  left: 0.5rem;
  padding: 0.5rem 1.5rem;
  transform: translateY(-120%);
  transition: transform 325ms ease-in;

  &:focus {
    position: absolute;
    transform: translateY(0);
  }
`

const SkipNav = () => {
  return (
    <React.Fragment>
      <a css={skipNavLink} href="#main-content">
        skip navigation
      </a>
    </React.Fragment>
  )
}

export default SkipNav
