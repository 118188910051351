import React from "react"
import { NavStyles, NavOverlayHeader, NavOverlayLinks } from "./NavElements"
import Navigation from "../Navigation"

const NavOverlay = ({ handleToggle }) => {
  return (
    <NavStyles className="nav-overlay" onClick={handleToggle}>
      <NavOverlayHeader>
        <h3>All Categories</h3>
        <p>View comprehensive articles about entrepreneurship</p>
      </NavOverlayHeader>
      <NavOverlayLinks>
        <Navigation handleToggle={handleToggle} footer={false} />
      </NavOverlayLinks>
    </NavStyles>
  )
}

export default NavOverlay
