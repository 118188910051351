import React, { useRef, useEffect } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
// import { FaSearch as SearchIcon } from "react-icons/fa"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => {
    const inputRef = useRef(null)

    useEffect(() => {
      inputRef.current.focus()
    })

    return (
      <form className={className}>
        <input
          className="SearchInput"
          type="text"
          placeholder="Search businessfrontal.com"
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
          ref={inputRef}
        />
        {/* <SearchIcon className="SearchIcon" /> */}
      </form>
    )
  }
)
