import styled from "@emotion/styled"

export const FooterStyles = styled.footer`
  background-color: var(--grey-1);
  border-top: 1px solid var(--grey-3);
  grid-column: 1 / -1;
  padding-bottom: 2rem;
  padding-top: 4rem;

  display: grid;
  grid-template-columns: var(--default-grid-cols);

  .small {
    font-size: var(--smallFont);
  }

  .muted {
    color: var(--grey-7);
  }

  a {
    opacity: 0.75;

    &:hover,
    &:focus,
    &.activeLite {
      opacity: 0.95;
      color: var(--primaryRed);
    }
  }
`

export const FooterWrapper = styled.div`
  grid-column: center-start / center-end;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;

  h4 {
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  @media (max-width: 60em) {
    gap: 2rem;
    flex-direction: column;
  }
`

export const CopyrightWrapper = styled.div`
  --flex-align: space-between;
  display: flex;
  flex-direction: column;
  justify-content: var(--flex-align);
  flex: 0 1 min(13rem, 100%);

  p {
    margin-top: 1rem;
  }

  @media (max-width: 60em) {
    border-top: 1px solid var(--grey-3);
    padding-top: 2rem;
    order: 2;
    flex: 1;
    flex-direction: row;
  }
`

export const CopyrightContent = styled.div`
  margin-top: auto;
`

export const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 60em) {
    order: 0;
    flex: 1;
  }
`
export const InfoLinks = styled.div`
  flex-basis: 1fr;

  display: flex;
  flex-direction: column;

  .best-spinner {
    margin-block-start: auto;
    color: var(--grey-6);
  }

  .spacing {
    padding-top: 2rem;
  }

  @media (max-width: 60em) {
    order: 1;
    flex: 1;
  }
`

export const InfoLinksList = styled.ul`
  @media (max-width: 60em) {
    display: flex;

    a {
      padding-right: 0.5rem;
      margin-right: 0.5rem;
    }

    li:not(:last-of-type)::after {
      content: "/";
      position: relative;
      margin-right: 0.5rem;
      color: var(--grey-5);
    }
  }
`
