import { Link } from "gatsby"
import styled from "@emotion/styled"

export const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  justify-content: center;
  align-items: center;
  padding-block: 1rem;
`

export const BannerLink = styled(Link)`
  display: inline-block;
  max-width: 36rem;
  opacity: initial;

  @media (max-width: 40em) {
    max-width: 22rem;
  }
`
